<!--<div>-->
    <!--<mat-sidenav-container>-->
        <!--<mat-sidenav>-->
            <!--<jhi-directory-tree (selectedDictionary)="onSelectedDictionary($event)"></jhi-directory-tree>-->
        <!--</mat-sidenav>-->
        <!--<mat-sidenav-container>-->
            <!--<jhi-document-list (selectedDocuments)="onSelectedDocuments($event)" [directoryId]="selectedDirectoryId"></jhi-document-list>-->
        <!--</mat-sidenav-container>-->
    <!--</mat-sidenav-container>-->
<!--</div>-->
<div fxFlex="column">
    <jhi-alert></jhi-alert>
    <div fxFlex="row">
        <jhi-directory-tree (selectedDictionary)="onSelectedDictionary($event)"
                            [refresh]="directoryGenerated"
                            [ddvSettings]="ddvSettings"
                            [listDirectorySelection]="listDirectorySelection"
                            (refreshDocuments)="onRefreshDocuments($event)"
                            fxFlex>
        </jhi-directory-tree>
        <mat-divider [vertical]="true"></mat-divider>

        <jhi-document-list *ngIf="ddvSettings && selectedDirectory && (selectedDirectory.nodeType==='DIRECTORY' || selectedDirectory.nodeType==='QA')"
                           (selectedDocuments)="onSelectedDocuments($event)"
                           [directoryId]="selectedDirectory?.directoryId"
                           [directoryName]="selectedDirectory?.directoryName"
                           [nodeType]="selectedDirectory?.nodeType"
                           [indexCumulate]="selectedDirectory?.indexCumulate"
                           [ddvSettings]="ddvSettings"
                           (directoryAdded)="onDirectoryCreaction($event)"
                           (directorySelected)="onListDirectorySelection($event)"
                           (viewFileProcessingState)="viewFileProcessingState($event)"
                           [refresh]="refreshDocuments"

                           fxFlex="grow">
        </jhi-document-list>
        <management-document-list *ngIf="ddvSettings && selectedDirectory && selectedDirectory.nodeType!='DIRECTORY' && selectedDirectory.nodeType!='QA'"
                           (selectedDocuments)="onSelectedDocuments($event)"
                           [directoryId]="selectedDirectory?.directoryId"
                           [directoryName]="selectedDirectory?.directoryName"
                           [nodeType]="selectedDirectory?.nodeType"
                           [ddvSettings]="ddvSettings"
                           (directoryAdded)="onDirectoryCreaction($event)"
                           (directorySelected)="onListDirectorySelection($event)"fxFlex="grow">
        </management-document-list>
        <mat-divider [vertical]="true"></mat-divider>
        <jhi-document-note [documentsIds]="selectedDocuments" *ngIf="selectedDocuments && selectedDocuments.length > 0" fxFlex="0 0 320px"></jhi-document-note>
    </div>
</div>
