import {AfterViewInit, Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FocusMonitor} from '@angular/cdk/a11y';
import {DirDTO} from "@entities/directory-document/directory-tree.component";
import {TranslateService} from "@ngx-translate/core";

export interface UploadDialogData {
    directoryId: number;
    documentId?: number;
}

@Component({
    selector: 'upload-dialog',
    templateUrl: 'upload.component.html',
    styleUrls: ['directory-document.scss']
})
export class UploadDialogComponent implements  AfterViewInit{

    id: number;
    documentId: number;
    endOfUploading = false;
    uploadedFileCount = 10;
    info = '';

    constructor(
        public dialogRef: MatDialogRef<UploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UploadDialogData,
        private _focusMonitor: FocusMonitor,
        private translateService: TranslateService
    ) {
        this.id = this.data.directoryId;
        this.documentId = this.data.documentId;
        this.info = this.translateService.instant('document.upload-result-info', {'total': this.uploadedFileCount})
    }

    ngAfterViewInit(): void {
        this._focusMonitor.stopMonitoring(document.getElementById('clear'));
        this.endOfUploading = false;
    }

    close(): void {
        this.dialogRef.close({viewFileProcessingState:  false});
    }

    onUploadCompleted(event) {
        this.uploadedFileCount = event;
        this.endOfUploading = true;
        this.info = this.translateService.instant('document.upload-result-info', {'total': this.uploadedFileCount})
    }

    viewFiles(): void {
        this.dialogRef.close({viewFileProcessingState:  true});
    }

}

