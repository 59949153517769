<div fxLayout="column">
    <div fxLayout="column">
        <div class="modal-header">
            <!--<span jhiTranslate="report.archive.dialog.title">-->
            <h4 class="modal-title">Przetwarzanie pliku</h4>
            <mat-divider></mat-divider>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                    (click)="close()">&times;
            </button>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div style="overflow: auto; height: calc(55vh)">
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="documentEventHistoryType">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b>Zdarzenie</b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    <span jhiTranslate="{{'extended-status.' + element.documentEventHistoryType}}">
                        {{ element.documentEventHistoryType }}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b>Data</b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.date | date:'dd.MM.yyyy HH:mm:ss'}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="info">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b>Dodatkowa informacja</b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.info }}
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
    </div>
</div>

