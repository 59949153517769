import {Component} from '@angular/core';
import {DirDTO} from './directory-tree.component';
import {SelectedItem} from './document-list.component';
import {DirectoryDocumentService} from './directory-document.service';
import {HttpResponse} from '@angular/common/http';
import {DirectoryDocumentViewSettingsDto} from './dto/directory-document-view-settings-dto.model';
import {ClipboardService} from 'ngx-clipboard';

@Component({
    selector: 'jhi-directory-document',
    templateUrl: 'directory-document-note.component.html',
    styleUrls: ['directory-document.scss'],
})
export class DirectoryDocumentNoteComponent {
    selectedDirectory: DirDTO;
    selectedDocuments: SelectedItem[];
    directoryGenerated: number;
    ddvSettings: DirectoryDocumentViewSettingsDto;
    listDirectorySelection: number;
    refreshDocuments: number;

    constructor(
        private directoryDocumentService: DirectoryDocumentService,
        public clipboardService: ClipboardService

    ) {
        this.directoryDocumentService.getSettings()
            .subscribe((res: HttpResponse<DirectoryDocumentViewSettingsDto>) => {
                this.ddvSettings = res.body;
            });
    }

    onSelectedDictionary(dto: DirDTO) {
        this.selectedDirectory = dto;
        this.selectedDocuments = [];
    }

    onSelectedDocuments(documents: SelectedItem[]) {
        this.selectedDocuments = documents;
    }

    onDirectoryCreaction(random: number) {
        this.directoryGenerated = random;
    }

    onListDirectorySelection(selectedDirectoryId: number) {
        this.listDirectorySelection = selectedDirectoryId;
    }

    onRefreshDocuments(refresh: number) {
        this.refreshDocuments = refresh;
    }

    viewFileProcessingState(event: any) {
        this.selectedDirectory = new DirDTO(-2, null, null, 'DAMAGED');
    }
}
