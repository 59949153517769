import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {DocumentVdrService} from "@entities/document-vdr";
import {
    DocumentEventHistoryDto
} from "@entities/directory-document/document-event-history/document-event-history-dto.model";
import {Principal} from "@shared/auth/principal.service";

@Component({
    templateUrl: 'document-event-history-dialog.html'
})
export class DocumentEventHistoryDialog implements OnInit {

    dataSource = new MatTableDataSource<DocumentEventHistoryDto>();
    displayedColumns = ['documentEventHistoryType', 'date', 'info'];
    constructor(public dialogRef: MatDialogRef<DocumentEventHistoryDialog>,
                private documentVdrService: DocumentVdrService,
                private principal: Principal,
                @Inject(MAT_DIALOG_DATA) public data: number) {
    }

    ngOnInit(): void {
        this.displayedColumns = this.principal.hasAnyAuthorityDirect(['SUPER_ADMIN']) ?
            ['documentEventHistoryType', 'date', 'info'] :
            ['documentEventHistoryType', 'date'];

        this.documentVdrService.getDocumentEventHistories(this.data)
            .subscribe((result) => this.dataSource.data = result);
    }

    close(): void {
        this.dialogRef.close();
    }
}
